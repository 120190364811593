<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Tab cards -->
      <div class="col">
        <h4 class="fw-bold text-primary">
          <span v-if="!selectedTemplate">Health Tracker Templates</span>
          <span v-else>{{ selectedTemplate.title }}</span>
        </h4>
        <div v-if="!selectedTemplate">
          <div class="row mb-3" v-if="!showAddNew">
            <div class="col">
              <p class="alert alert-primary">
                <i class="far fa-info me-2"></i>
                Build your clinic Health Tracker templates here. You can
                share them directly with clients via their client area.
              </p>
            </div>
          </div>
          <busy :visible="loading" />
          <div v-if="!loading">
            <div
              class="row mb-3"
              v-if="templates.length === 0 && !showAddNew"
            >
              <div class="col-6 mx-auto text-center">
                <i class="far fa-info-circle text-primary fa-5x mt-2"></i>
                <h5 class="my-3">No Health Tracker templates found.</h5>
                <button
                  class="btn btn-outline-primary"
                  @click="showAddNew = true"
                >
                  <i class="far fa-plus me-1"></i>
                  Create New Template
                </button>
              </div>
            </div>
            <div v-if="showAddNew">
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <label>Create new template</label>
                  <input
                    type="text"
                    class="form-control mb-3"
                    v-model="newTemplateName"
                    @keypress.enter="createTemplate"
                    placeholder="Template name..."
                  />
                  <button
                    class="btn btn-primary float-end"
                    @click="createTemplate"
                    :disabled="newTemplateName.length < 1"
                  >
                    <i class="far fa-save me-1"></i>
                    Create Template
                  </button>
                  <button
                    class="btn btn-light me-2 float-end"
                    @click="showAddNew = false"
                  >
                    <i class="far fa-times me-1"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div v-if="templates.length > 0 && !showAddNew">
              <div class="row mb-3">
                <div class="col">
                  <button
                    class="float-end btn btn-outline-primary mb-3"
                    @click="showAddNew = true"
                  >
                    <i class="far fa-plus me-1"></i>
                    Create New Template
                  </button>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Created</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="t in templates"
                        :key="t.id"
                        class="cursor-pointer"
                        @click="openTemplate(t)"
                      >
                        <td>{{ t.title }}</td>
                        <td>{{ t.created_at | formatDate }}</td>
                        <td class="text-end">
                          <i
                            class="far fa-trash cursor-pointer"
                            v-tooltip.bottom="'Delete Template'"
                            @click.stop="deleteTemplate(t)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p class="alert alert-primary">
            <i class="far fa-info me-2"></i>
            Add questions to your Health Tracker template. You can drag and
            drop questions to re-arrange the order. Changes are saved
            automatically.
          </p>
          <div class="row">
            <div class="col">
              <div class="card bg-light">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col">
                          <h5>
                            Template Questions
                            <button
                              class="
                                btn btn-outline-primary btn-sm
                                float-end
                              "
                              @click="showPreview"
                            >
                              <i class="far fa-eye me-1"></i>
                              Preview
                            </button>
                          </h5>
                          <draggable
                            class="list-group list-group-flush"
                            tag="ul"
                            handle=".handle"
                            v-model="selectedTemplate.questions"
                            v-bind="dragOptions"
                            @change="updateQuestionsOrder"
                          >
                            <li
                              class="list-group-item mb-2"
                              style="cursor: pointer !important"
                              v-for="q in selectedTemplate.questions"
                              :key="q.order"
                            >
                              <i
                                class="fa fa-arrows-alt handle me-3"
                                style="cursor: move"
                              ></i>
                              <span class="me-2">{{ q.order }}.</span>
                              {{ q.question }}
                              <i
                                class="float-end far fa-trash"
                                v-tooltip.bottom="'Delete Question'"
                                @click.stop="deleteQuestion(q)"
                              ></i>
                              <i
                                class="float-end far fa-wrench me-3"
                                v-tooltip.bottom="'Edit Question'"
                                @click.stop="
                                  editQuestion && editQuestion.id === q.id
                                    ? (editQuestion = null)
                                    : (editQuestion = q)
                                "
                              ></i>
                              <div
                                class="row bg-light py-3 mt-2"
                                v-if="
                                  editQuestion && editQuestion.id === q.id
                                "
                              >
                                <div class="col-6 mx-auto">
                                  <div class="card">
                                    <div class="card-body text-start">
                                      <label> Question </label>
                                      <input
                                        type="text"
                                        class="form-control mb-3"
                                        @change="updateQuestion"
                                        v-model="editQuestion.question"
                                      />
                                      <label> Additional Text </label>
                                      <textarea
                                        class="form-control mb-3"
                                        rows="3"
                                        maxlength="500"
                                        v-model="
                                          editQuestion.additional_text
                                        "
                                        @blur="updateQuestion"
                                        placeholder="Supplementary text displayed to the client alongside the question (max 500 characters)"
                                      ></textarea>
                                      <div
                                        class="
                                          custom-control custom-switch
                                          mb-3
                                        "
                                        v-tooltip.bottom="
                                          'Allows the client to enter a comment after their rating.'
                                        "
                                      >
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="enable_comments_edit"
                                          v-model="
                                            editQuestion.enable_comments
                                          "
                                          :checked="
                                            editQuestion.enable_comments
                                          "
                                          @change="updateQuestion"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="enable_comments_edit"
                                        >
                                          Enable Comments
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </draggable>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <button
                            class="btn btn-outline-primary"
                            v-if="
                              !showAddQuestion &&
                              selectedTemplate.questions.length
                            "
                            @click="showAddQuestion = true"
                          >
                            <i class="far fa-plus me-1"></i>
                            Add Health Tracker Question
                          </button>
                        </div>
                      </div>
                      <div
                        class="row"
                        v-if="
                          showAddQuestion ||
                          !selectedTemplate.questions.length
                        "
                      >
                        <div class="col-6 mx-auto">
                          <div class="card">
                            <div class="card-body text-start">
                              <label> Question </label>
                              <input
                                type="text"
                                class="form-control mb-3"
                                placeholder="Enter a question to be rated on a scale of 1-10"
                                @keypress.enter="addQuestion"
                                v-model="newQuestion.question"
                              />
                              <label> Additional Text </label>
                              <textarea
                                class="form-control mb-3"
                                rows="3"
                                maxlength="500"
                                v-model="newQuestion.additional_text"
                                placeholder="Supplementary text displayed to the client alongside the question (max 500 characters)"
                              ></textarea>
                              <div
                                class="custom-control custom-switch mb-3"
                                v-tooltip.bottom="
                                  'Allows the client to enter a comment after their rating.'
                                "
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="enable_comments"
                                  v-model="newQuestion.enable_comments"
                                  :checked="newQuestion.enable_comments"
                                />
                                <label
                                  class="custom-control-label"
                                  for="enable_comments"
                                >
                                  Enable Comments
                                </label>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <button
                                    class="btn btn-light"
                                    @click="showAddQuestion = false"
                                  >
                                    <i class="far fa-times me-1"></i>
                                    Cancel
                                  </button>
                                  <button
                                    class="
                                      btn btn-outline-primary
                                      float-end
                                    "
                                    :disabled="
                                      newQuestion.question.length === 0
                                    "
                                    @click="addQuestion"
                                  >
                                    <i class="far fa-plus me-1"></i>
                                    Add Health Tracker Question
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PREVIEW MODAL-->
    <div
      class="modal fade"
      id="previewMymopModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="expandedTaskModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content" v-if="selectedTemplate">
          <div class="modal-header">
            <h5 class="modal-title" id="expandedTaskModalTitle">
              {{ selectedTemplate.title }} Preview
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="row mb-3"
              v-for="question in selectedTemplate.questions"
            >
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <p>{{ question.question }}</p>
                    <vue-slider
                      class="mb-5 mx-2"
                      :min="0"
                      :max="10"
                      :interval="1"
                      :marks="true"
                    />
                    <p v-if="question.additional_text">
                      {{ question.additional_text }}
                    </p>
                    <textarea
                      v-if="question.enable_comments"
                      class="form-control"
                      placeholder="Add a comment (max 500 characters)"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close Preview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  props: ["path", "user"],
  data() {
    return {
      loading: true,
      templates: [],
      showAddNew: false,
      showAddQuestion: false,
      newTemplateName: "",
      selectedTemplate: null,
      newQuestion: {
        question: "",
        enable_comments: false,
        additional_text: "",
      },
      editQuestion: null,
    };
  },
  watch: {},
  methods: {
    fetchMymopTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/mymop/templates")
        .then(({ data }) => {
          this.templates = data;
          this.loading = false;
        });
    },
    refreshSelectedTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/mymop/templates/" +
            this.selectedTemplate.id
        )
        .then(({ data }) => {
          this.selectedTemplate = data;
        });
    },
    createTemplate() {
      this.loading = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/mymop/templates", {
          title: this.newTemplateName,
        })
        .then(({ data }) => {
          this.fetchMymopTemplates();
          this.$EventBus.$emit("alert", data);
          this.showAddNew = false;
          this.newTemplateName = "";
          this.loading = false;
        });
    },
    deleteTemplate(template) {
      if (
        confirm(
          "Are you sure you wish to delete this template, this action cannot be undone?"
        )
      ) {
        this.loading = true;
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/mymop/templates/" + template.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchMymopTemplates();
          });
      }
    },
    openTemplate(template) {
      this.selectedTemplate = template;
    },
    addQuestion() {
      this.loading = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/mymop/templates/" +
            this.selectedTemplate.id +
            "/questions",
          this.newQuestion
        )
        .then(({ data }) => {
          this.selectedTemplate = data.template;
          this.$EventBus.$emit("alert", data);
          this.showAddNew = false;
          this.showAddQuestion = false;
          this.newQuestion = {
            question: "",
            enable_comments: false,
            additional_text: "",
          };
          this.loading = false;
        });
    },
    deleteQuestion(question) {
      if (
        confirm(
          "Are you sure you wish to delete this question, this action cannot be undone?"
        )
      ) {
        this.loading = true;
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/mymop/questions/" + question.id
          )
          .then(({ data }) => {
            this.selectedTemplate = data.template;
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    updateQuestionsOrder(event) {
      if (event.moved) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/mymop/templates/" +
              this.selectedTemplate.id +
              "/order-questions",
            this.selectedTemplate.questions
          )
          .then(({ data }) => {
            this.selectedTemplate = data.template;
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    updateQuestion() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/mymop/questions/" +
            this.editQuestion.id,
          this.editQuestion
        )
        .then(({ data }) => {
          this.selectedTemplate = data.template;
          this.$EventBus.$emit("alert", data);
        });
    },
    showPreview() {
      $("#previewMymopModal").modal("show");
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  mounted() {
    this.fetchMymopTemplates();

    $("#previewMymopModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  components: {
    VueSlider,
  },
};
</script>

<style>
</style>
